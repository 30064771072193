import { useEffect, useState } from 'react';
import joe from './images/joe.png';
//import saloon from './images/saloon.png';
import cactus from './images/cactus.png';
import wanted from './images/wanted.png';
import sheriff from './images/sheriff-star.png';
import dynamite from './images/tnt.png';
import colts from './images/crossed-colts.png';
import useSound from 'use-sound';
import winchesterSlotStop from './audios/winchester_slot_stop.m4a'
import rollingSound from './audios/rolling_reels.m4a'


const imgStyle = {maxWidth: '7vw', height: '20vh', objectFit: 'contain'};
const joeImg = <img src={joe} style={imgStyle} />;
//const saloonImg = <img src={saloon} style={imgStyle} />;
const cactusImg = <img src={cactus} style={imgStyle} />;
const wantedImg = <img src={wanted} style={imgStyle} />;
const sheriffImg = <img src={sheriff} style={imgStyle} />;
const dynamiteImg = <img src={dynamite} style={imgStyle} />;
const coltsImg = <img src={colts} style={imgStyle} />;

const imgs = [
    //saloonImg,
    wantedImg,
    joeImg,
    cactusImg,
    sheriffImg,
    dynamiteImg,
    coltsImg
]

export default ({playing, moveDuration, initialPositions, resultPositions, onStopped, slotId}) => {
    const [moving, setMoving] = useState(false);
    const [started, setStarted] = useState(false);
    const [playSlotStop] = useSound(winchesterSlotStop, {volume: .1}/*, { playbackRate: 0.5 }*/);
    const [playRolling, rollingAudio] = useSound(rollingSound, {volume: .2})



    function play() {
        setStarted(true);
        setMoving(true);   
        playRolling();

        setTimeout(() => {
            setMoving(false);
            playSlotStop();
            rollingAudio.stop();
            onStopped && onStopped(resultPositions);
        }, moveDuration||3000)
    }

    useEffect(() => {
        if (playing && !moving) play();
    }, [playing])

    const positions = started ? resultPositions : initialPositions;

    return ( 
        <div className="flex slot">
            <div className="flex row innerslot">
                {
                    moving 
                    ? <>
                        {
                            positions
                            &&[...positions, ...positions].map((r, ri) => <div key={`ip${ri}`} className="ringMoving">{ imgs[r] }</div>)

                        }
                    </>
                    : <>
                        {
                            positions?.map((r, ri) => <div key={`rp${ri}`} className="ringEnd">{ imgs[r] }</div>)
                        }
                    </>
                }
            </div>

            

        </div>
    )
}