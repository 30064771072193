import './bulb.css';
import bulbFront from '../images/bulbs/bulb-front.png';
import { createRef, useEffect, useRef, useState } from 'react';

const colors = {
    red: '187, 20, 21',
    yellow: '252, 229, 16',

}

const Bulb = ({ flicker, size, rgb, _ref, onOffMode, modeStartsOn }) => {
    const [positions, setPositions] = useState([0,0]);
    

    const whStyle = {
        maxWidth: size, 
        maxHeight: size, 
        minWidth: size, 
        minHeight: size,
        borderRadius: '50%',
    };

    useEffect(() => {
        const { x, y } = _ref?.current?.getBoundingClientRect() || {}
        setPositions([x, y]);

    }, [_ref?.current]);


    return <>
        <img 
            src={bulbFront} 
            style={{...whStyle, opacity: .3}} 
            ref={_ref}
        />
        <div 
            className={flicker ? `bulb-${flicker}` : (onOffMode ? `bulb-mode-${modeStartsOn ? 'on' : 'off'}` : '')} 
            style={{
                ...whStyle, 
                // position: 'relative', 
                // top: -size, 
                position: 'absolute',
                top: positions?.length && positions[1],
                left: positions?.length && positions[0],
                background: `rgba(${rgb}, .8)`, 
                boxShadow: `2px 2px 30px 10px rgba(${rgb},.9) `
            }} 
        />
    </>
}

export default ({ size, rgb, color, flicker: _flicker, inline, row, onOffMode, containerClass }) => {

    const [refs, setRefs] = useState(inline?.map(() => useRef()));
    
    const _size = size || 20;
    const _rgb = color ? colors[color] : rgb;

    const flexRule = inline ? `flexy ${row ? 'flexrow' : ''} ${containerClass || ''}` : '';
    
    return <div className={flexRule}>
        {
            inline?.length
            ? inline.map((bulb, bulbi) => {
                const { size, color, rgb, flicker } = bulb;
                
                return <Bulb 
                    _ref={refs?.length && refs[bulbi]}
                    key={`bulb_${bulbi}`}
                    flicker={flicker || _flicker}
                    size={size || _size}
                    rgb={(color ? colors[color] : rgb) || _rgb}
                    onOffMode={onOffMode}
                    modeStartsOn={bulbi%2 === 0}
                    
                />
            })
            : <Bulb 
                flicker={_flicker}
                size={_size}
                rgb={_rgb}
            />
        }
    </div>
    //<div className="bulb" style={{width: _size, height: _size}}></div>
}