import joe from './images/joe.png';
import cactus from './images/cactus.png';
import wanted from './images/wanted.png';
import sheriff from './images/sheriff-star.png';
import dynamite from './images/tnt.png';
import colts from './images/crossed-colts.png';
import bell from './images/bell.png';
import { useEffect, useState } from 'react';

const imgSources = {
    cactus, wanted, sheriff, dynamite, colts, joe
}


const RuleItem = ({
    images, subtitle, gain, bells, bellsTag
}) => {

    return <div className="flexy allwidth flexrow rule-item spacebetween">
        <div className='flex flex-5'>
            <div className='flex flexrow rounded'>
                {
                    images.map((i, ii) => {
                        return <img key={`board_img_${ii+i}`} src={imgSources[i]} alt="" className='rule-img' />
                    })
                }
                
            </div>
            {subtitle}
        </div>
        
        <div className='flex flex-25 flexrow xxlarge cowboy'>
            {gain}
        </div>
        <div className='flex flex-25'>
            <div className='flex flexrow'>
                {
                    Array.from(new Array(bells)).map((b, bi) => <img key={`board_bell_${gain}${bi}`} src={bell} className='bell-img' alt='' />)
                }
            </div>
            { bellsTag }
        </div>
    </div>
}



export default ({ visible }) => {
    const [slices, setSlices] = useState([0,4])

    function switchDynamicImgs() {
        const s1 = ((slices[0] + 1) > 5) ? 0 : (slices[0] + 1);
        const s2 = ((slices[1] + 1) > 5) ? 0 : (slices[1] + 1);
        setSlices([s1, s2]);
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            switchDynamicImgs()
        }, 4000)
        return () => clearTimeout(timer);

    }, [slices]);

    const dynamicImgs = (
        slices[1] > slices[0]
        ? Object.keys(imgSources).slice(slices[0],slices[1])
        : [
            ...Object.keys(imgSources).slice(slices[0], 5), // exclude joe for other figures lines
            ...Object.keys(imgSources).slice(0, slices[1])
        ]
    );

    /* 2 Dynamites */
    const dynamicImgsWithoutDynamites = (dynamicImgs?.length===3 ? [...dynamicImgs, dynamicImgs[0]/*'joe'*/] : dynamicImgs).map(e => ((e==='dynamite') ? 'joe' : e));
    const index1 = Math.round(Math.random() * 3);
    const randIndex2 = Math.round(Math.random() * 2);
    const index2 = ([0,1,2,3].filter((e) => ( e !== index1 )))[randIndex2];
    const twoUnlinedDynamites = ['dynamite', 'dynamite', 'dynamite', 'dynamite'].map((e, i) => (
        (i===index1)
        ? dynamicImgsWithoutDynamites[index1]
        : (
            (i===index2)
            ? dynamicImgsWithoutDynamites[index2]
            : e
        )
    ));

    return <div className={`flex allspace rules-table drop-shadowed black justifystart ${visible ? 'slide-in-left' : 'slide-out-left'}`}>
        <div className='flex flex-1 bold cowboy marged-top'>
            <p className="xxlarge rules-table-title">JOE'$ JACKPOT</p>
            <p className="bold cowboy large marged-top rules-table-subtitle">Deal Board</p>
        </div>
        <div className="flex flex-9 justifystart rules-table-inner">
            <div className='bold cowboy large flexy flexrow allwidth bordered-bottom'>
                <p className='flexy flex-5'>FIGURES</p>
                <p className='flexy flex-25 bordered-right bordered-left'>GAINS</p>
                <p className='flexy flex-25 alignend'>BELLS</p>
            </div>
            <div className='flex spaceevenly allspace rules-items'>
                <RuleItem
                    images={['joe', 'joe', 'joe', 'joe']}
                    gain='X40'
                    bells={4}
                    bellsTag='big ring'
                    subtitle='4 Joes Aligned'
                />
                <RuleItem
                    images={[dynamicImgs[0], dynamicImgs[0], dynamicImgs[0], dynamicImgs[0]]}
                    gain='X20'
                    bells={4}
                    bellsTag='long ring'
                    subtitle='4 Aligned'

                />
                <RuleItem
                    images={[dynamicImgs[1], dynamicImgs[1], dynamicImgs[1], dynamicImgs[0]]}
                    gain='X5'
                    bells={3}
                    bellsTag='short ring'
                    subtitle='3 Aligned'
                />
                <RuleItem
                    images={[dynamicImgs[0], dynamicImgs[0], dynamicImgs[1], dynamicImgs[1]]}
                    gain='X3'
                    bells={2}
                    bellsTag='3 rings'
                    subtitle='A-A-B-B Pattern'

                />
                <RuleItem
                    images={[dynamicImgs[0], dynamicImgs[1], dynamicImgs[0], dynamicImgs[1]]}
                    gain='X2'
                    bells={2}
                    bellsTag='3 rings'
                    subtitle='A-B-A-B Pattern'
                />
                <RuleItem
                    images={twoUnlinedDynamites}
                    gain='X1'
                    bells={1}
                    bellsTag='1 ring'
                    subtitle='2 Dynamites'
                />
            </div>
        </div>
        <div className='large cowboy marged-top bold'>The Establishment Wishes You A Good Luck</div>

    </div>
}