import { useEffect, useState } from 'react'
import useSound from 'use-sound'

import SlotMachine from './SlotMachine'
import Button from './glowing-button/Button'

import saloonAmbiance from './audios/saloon_ambiance.m4a'

import './App.css';
import { isMobile } from './layout'
// import Web3Connect from './Web3Connect'

export default () => {
    const [launched, setLaunched] = useState(true);
    const [saloonVolume, setSaloonVolume] = useState(.05);
    const [soundLoaded, setSoundLoaded] = useState(false);
    const [account, setAccount] = useState(undefined);
    const [isLandscape, setIsLandscape] = useState(window.innerWidth >= window.innerHeight);

    const [saloonAmbiancePlaying, setSaloonAmbiancePlaying] = useState(true);
    const [playSaloonAmbiance, saloonAmbianceAudio] = useSound(saloonAmbiance, {  volume: saloonVolume, onload: () => setSoundLoaded(true) })

    function toggleAmbiance() {
        saloonAmbiancePlaying
          ? saloonAmbianceAudio.stop()
          : playSaloonAmbiance();
          setSaloonAmbiancePlaying(!saloonAmbiancePlaying);
      }

    function launch() {
        setSaloonAmbiancePlaying(true);
        setLaunched(true);
        //playSaloonAmbiance();
    }

    useEffect(() => {
        /* Window Resize */
        function handleResize() {
          console.log('resized to: ', window.innerWidth, 'x', window.innerHeight);
          isMobile && setIsLandscape(window.innerWidth >= window.innerHeight);
        }
        window.addEventListener('resize', handleResize);

        
    });

    console.log('isLandscape', isLandscape);
    
    return <div className='flex screenspace'>
        {/* <div className='flex flex-1 allwidth width90p alignend'>

            <Web3Connect setAccount={setAccount} />

        </div>
        <div className='flex flex-9'> */}
            {
                launched
                ? <SlotMachine 
                    soundLoaded={soundLoaded} 
                    setVolume={setSaloonVolume} 
                    toggleAmbiance={toggleAmbiance} 
                    ambiancePlaying={saloonAmbiancePlaying} 
                    setAmbiancePlaying={setSaloonAmbiancePlaying} 
                    playAmbiance={playSaloonAmbiance} 
                    account={account}
                    restrictToLandscape={isMobile}
                    isLandscape={isLandscape}
                />
                : <div className='flex'>
                    {
                        account
                        && <Button className="app-button" onClick={launch}>Launch Slot Machine</Button>
                        
                    }
                </div>
            }
        {/* </div> */}
        
    </div>
}