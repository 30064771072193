import { useEffect, useRef, useState } from "react"
import Slot from "./Slot"
import './machine.css';
import Neon from "./neon-text/Neon";
import insertCoin from './images/insert-coin.png';
import { computeResult, generateRandomSlotResults } from "./utils";
import Bulb from "./bulb/Bulb";
import joe2 from './images/joe2.png';
import moneybag from './images/moneybag.png';
import betOne from './images/betone.png';
import betLess from './images/betless.png';
import betMax from './images/betmax.png';
import DigitalScreen from './digital-screen/DigitalScreen';
import useSound from 'use-sound';
import winchester from './audios/winchester_lever.m4a'
import buttonPush from './audios/button_push.m4a'
import insertCoinEffect from './audios/insert-coin.m4a'
import bell from './audios/bell.m4a'
import winBell from './audios/win-bell.m4a'
import jackpotEffect from './audios/jackpot.m4a'
import rust1 from './images/rust1.png';
import impact1 from './images/impact1.png';
import cancel from './images/cancel.png';
// import cashout from './images/cashout.png';
import cashout from './images/cashout-inop.png';
import {ImVolumeHigh, ImVolumeMute2} from 'react-icons/im';
import RulesTable from "./RulesTable";
import { BiArrowToLeft, BiArrowFromLeft } from 'react-icons/bi';
import { MdScreenLockRotation } from 'react-icons/md';
import { isMobile, vmax } from "./layout";

const MAX_BET = 5;

const ballSize = 60;


export default({
  soundLoaded,
  setVolume,
  ambiancePlaying,
  setAmbiancePlaying,
  playAmbiance,
  toggleAmbiance,
  isLandscape,
  restrictToLandscape
}) => {

    const [playing, setPlaying] = useState(false);
    const [results, setResults] = useState([]);
    const [bet, setBet] = useState(1);
    const [lastBet, setLastBet] = useState(0);
    const [credit, setCredit] = useState(50);
    const [sessionGains, setSessionGains] = useState(0);
    const [rulesVisible, setRulesVisible] = useState(true);
    const [playInsertCoin] = useSound(insertCoinEffect, { volume: 0.6 })
    const [playWinchester] = useSound(winchester, { playbackRate: 0.7, volume: .05 });
    const [playButtonPush] = useSound(buttonPush, {volume: .15});
    const [playButtonPull] = useSound(buttonPush, { playbackRate: 1.3, volume: .3 });
    const [playBell] = useSound(bell, { volume: .9 });
    const [playBellShort] = useSound(bell, { volume: .9, sprite: { short: [380, 2000] } });
    const [playWinBell] = useSound(winBell, { volume: .6 });
    const [playWinBellShort] = useSound(winBell, { volume: .6, sprite: { short: [7000, 8000] } });
    const [playJackpot] = useSound(jackpotEffect, { volume: .9 });
    const leverBallRef = useRef(null);
    const leverBarRef = useRef(null);

    const hideRules = () => setRulesVisible(false);
    const showRules = () => setRulesVisible(true);

    function elemAnimate(element, propertyChanges, duration, callback) {
        const start = performance.now();
        const initialStyles = getComputedStyle(element);
      
        requestAnimationFrame(function animateStep(timestamp) {
          const elapsed = timestamp - start;
          const progress = Math.min(elapsed / duration, 1);
      
          for (let property in propertyChanges) {
            if (propertyChanges.hasOwnProperty(property)) {
              const initialValue = parseFloat(initialStyles[property]);
              const targetValue = parseFloat(propertyChanges[property]);
              const currentValue = initialValue + (targetValue - initialValue) * progress;
              element.style[property] = currentValue + (property === 'opacity' ? '' : 'px');
            }
          }
      
          if (progress < 1) {
            requestAnimationFrame(animateStep);
          } else {
            if (callback) {
              callback();
            }
          }
        });
      }

    const handleLeverBallClick = () => {
      
        if (playing) return;
        if (!bet) return;
        if (!credit) return;

        setLastBet(bet);

        const newCredit = credit - bet;

        setCredit(newCredit);
        if (bet > newCredit) {
          setBet(newCredit);
        }

        playWinchester();

        /* RANDOM */
        setResults(generateRandomSlotResults());

        //setResults([[0, 1, 2, 3], [0, 1, 2, 3], [0, 1, 2, 3], [0, 1, 2, 3], ])
    
        const leverBall = leverBallRef.current;
        const leverBar = leverBarRef.current;
       
    
        // elemAnimate(
        //     leverBall,
        //   { marginTop: -200, height: 70, width: 70, marginRight: -15 },
        //   700,//1000,
        //   () => {
        //     elemAnimate(
        //         leverBall,
        //       { marginTop: -450, height: 60, width: 60, marginRight: -10 },
        //       1050//1500
        //     );
        //   }
        // );
      
        // elemAnimate(
        //     leverBar,
        //   { marginTop: -180, height: 200 },
        //   700,//1000,
        //   () => {
        //     elemAnimate(leverBar,{ marginTop: -390, height: 400 }, 1500);
        //   }
        // );

        /* SHORT VERSION */
        elemAnimate(
          leverBall,
          { marginTop: -(window.innerHeight*.2), height: 70, width: 70, marginRight: -15 },
          700,//1000,
          () => {
            elemAnimate(
                leverBall,
              { marginTop: -(window.innerHeight*.45), height: ballSize, width: ballSize, marginRight: -10 },
              1050//1500
            );
          }
        );
      
        elemAnimate(
          leverBar,
          { marginTop: -(window.innerHeight*.18), height: window.innerHeight*.2 },
          700,//1000,
          () => {
            elemAnimate(leverBar,{ marginTop: -(window.innerHeight*.39), height: window.innerHeight*.40 }, 1050);
          }
        );
        
      
      
        setPlaying(true);
    }

    function handleBetOne() {
      playButtonPush()

      if (!playing) {

        const b = bet + 1;

        if ((b <= MAX_BET) && (b <= credit)) {
          setBet(b);
        }
      }
    }

    function handleBetLess() {
      playButtonPush();

      if (!playing) {
        const b = bet - 1;

        if ((b > 0)) {
          setBet(b);
        }
      }
    }

    function handleBetMax() {
      playButtonPush()

      if (!playing) {

        let b = (
          credit <= MAX_BET
          ? credit
          : MAX_BET 
        );

        if (b >= 0) {
          //setCredit(c);
          setBet(b);
        }
      }
    }

    function handleCancelBet() {
      playButtonPush();
      !playing && setBet(credit>0 ? 1 : 0);
    }

    function handleCashout() {
      playButtonPush();

      if (!playing) {
        // handle cash out

      }
    }

    function handleInsertCoin() {
      // propose credit purchase
      playInsertCoin();
      setCredit(credit + 1);
    }

    const handleResults = async(_results) => {

      const {
        jackpot,
        fourInline,
        threeInline,
        aabbPattern,
        ababPattern,
        twoDynamites
        //minThreeJoes
      } = await computeResult(_results);

      console.log(
        'handleResults:', 
        {
          _results,
          lastBet,
          credit,
          jackpot,
          fourInline,
          threeInline,
          aabbPattern,
          ababPattern,
          twoDynamites
          //minThreeJoes
        }
      );

      
      /// handle winnings calculation, credit update and audio play
      // X40
      if (jackpot) {
        playJackpot();
        const gain = lastBet * 40;
        console.log('gain 1', gain);
        setSessionGains(gain);
        setCredit(credit + gain)
      }
      // X20
      else if (fourInline) {
        playWinBell()
        const gain = lastBet * 20;
        console.log('gain 2', gain);
        setSessionGains(gain);
        setCredit(credit + gain)
      }
      // X5
      else if(threeInline) {
        playWinBellShort({id: 'short'})
        const gain = lastBet * 5;
        console.log('gain 3', gain);
        setSessionGains(gain);
        setCredit(credit + gain)
      }
      // X3
      else if (aabbPattern) {
        playBell();
        const gain = lastBet * 3;
        console.log('gain 4', gain);
        setSessionGains(gain);
        setCredit(credit + gain)
      }
      // X2
      else if (ababPattern) {
        playBell();
        const gain = lastBet * 2;
        console.log('gain 4', gain);
        setSessionGains(gain);
        setCredit(credit + gain)
      }
      // X1 (Reimbursement)
      else if (twoDynamites) {
        playBellShort({id: 'short'})
        const gain = lastBet;
        console.log('gain 5', gain);
        setSessionGains(gain);
        setCredit(credit + gain);
      }
     

        
      setPlaying(false);
  }

  useEffect(() => {
      setTimeout(playAmbiance);
  }, [soundLoaded])


    return (
      restrictToLandscape && !isLandscape
      ? <div className="flex saloon-bg">
        <div className="flex allspace bluredbg">
          <MdScreenLockRotation color={'white'} size={window.innerWidth * .4} />
          <p className="xxxlarge white centered cowboy">Sorry Cowboy, <br />you need to flip your device to landscape mode</p>
        </div>
      </div>
      : <div className="flex saloon-bg ">
        <div className="flex flexrow allspace alignend bluredbg machine-wrapper">

            <div className="flex">
              {<RulesTable
                visible={rulesVisible}
              />}
            </div>

            <div className="flexy machine-core">
                <img src={impact1} className="xsmall-img rotated selfstart trapezoidal" style={{zIndex: 15,marginBottom: '-7.7vh', marginLeft: '55%', filter: 'drop-shadow(2px 2px 1px rgba(0,0,0,.3))'}}/>

                <div className="top-trapezoid allspace flex flexrow">
                  <Neon />
                  
                </div>
                
                <div className="top-rect flex alignstart">
                  
                    <div className="flex flexrow" >
                      <div className="flex flex-6 alignstart">
                        {/* <div className="machine-title xxxlarge white marged-left "> */}
                        <div className="machine-title white marged-left ">
                          <div className="flex flexrow">
                            Joe' $ Jackpot 
                            <img src={moneybag} className="medium-img "/>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-4 flexrow" >
                        {/* <img src={moneybag} className="medium-img selfend"/> */}
                        <img src={joe2} className="top-rect-img"/>
                      </div>
                      <img src={rust1} className="medium-img" style={{opacity: .8, position: "relative", bottom: '-4vw', left: 30, zIndex: 11}} />

                    </div>

                </div>
                
                <div className="machine-slots" style={{overflow: 'hidden'}}>
                  
                    <div className="slots flex flexrow">
                        <div className="before-slots"></div>
                        <Slot 
                          slotId='slot1'
                          playing={playing} 
                          //resultPositions={[0,1,2,3]} 
                          resultPositions={results[0]} 
                          initialPositions={[0,1,2,3,4,5]} 
                          moveDuration={2000}
                        />
                        <Slot 
                          slotId='slot2'
                          playing={playing} 
                          //resultPositions={[0,1,2,3]} 
                          resultPositions={results[1]} 
                          initialPositions={[1,2,3,4,5,0]} 
                          moveDuration={2500}
                        />
                        <Slot 
                          slotId='slot3'
                          playing={playing} 
                          //resultPositions={[1,0,2,3]} 
                          resultPositions={results[2]} 
                          initialPositions={[2,3,4,5,0,1]} 
                          moveDuration={3000}
                        />
                        <Slot 
                          slotId='slot4'
                          playing={playing} 
                          //resultPositions={[1,0,2,3]} 
                          resultPositions={results[3]} 
                          initialPositions={[3,4,5,0,1,2]} 
                          moveDuration={3700} 
                          onStopped={() => {
                            handleResults(Array.from(new Array(4)).map((e, i) => results[i][0]))
                          }} 
                        />
                        <div className="after-slots">
                          <img src={rust1} className="rotated" style={{position: 'relative', width: '7vh', left: '4vh', top: '-10vh'}} />
                        </div>
                        
                    </div>
                    
                    {/* Under Slots Actions Row */}
                    <div className="flex flexrow alignend justifystart machine-controls">

                      {/*  */}
                      <div className="flex flex-8 flexrow justifystart spaceevenly allwidth" >
                        <img src={rust1} className="medium-img rotated" style={{opacity: .8, position: "relative", bottom: -100, left: '-2vw', zIndex: 10}} />

                        <div className="flexy flex-3 machine-left-screens spaceevenly" style={{marginLeft: '-7vw'}}>
                          <DigitalScreen
                            length={5}
                            value={bet}
                            title={'bet'}
                          />
                          <DigitalScreen
                            green
                            length={7}
                            value={sessionGains}
                            title='gain'
                            className="marged-top-tiny"
                            //blinking={(sessionGains > 0) && (!credit)}
                          />
                        </div>
                        <div className="flex flex-2 allspace spaceevenly" style={{marginLeft: 10, marginTop: 10}}>
                          <div className="flex flexrow allwidth spaceevenly">
                            <img src={betLess} className="machine-button" onMouseDown={handleBetLess} onMouseUp={playButtonPull} />
                            <img src={betOne} className="machine-button" onMouseDown={handleBetOne} onMouseUp={playButtonPull} />
                          </div>
                          &nbsp;
                          <div className="flex flexrow allwidth spaceevenly">
                            <img src={betMax} className="machine-button" onMouseDown={handleBetMax} onMouseUp={playButtonPull} />
                            <img src={cancel} className="machine-button" onMouseDown={handleCancelBet} onMouseUp={playButtonPull}/>

                          </div>

                        </div>
                   
                        <div className="flexy flex-3">
                          <DigitalScreen
                            green
                            length={6}
                            value={credit}
                            title={'credit'}
                            blinking={!playing && credit===0}
                            />
                            <img src={impact1} className="xsmall-img rotated selfstart" style={{position: 'relative', top: -9, left: 15, filter: 'drop-shadow(2px 2px 1px rgba(0,0,0,.3))'}}/>
                            <img src={cashout} className="machine-button machine-button-big machine-cashout-button" onMouseDown={handleCashout} onMouseUp={playButtonPull}/>
                        </div>
                        
                      </div>
                      <div className="flex flex-2 alignend">
                       
                        <div className="flex flexrow justifyend selfend" style={{overflow: 'hidden'}}>
                          <img src={impact1} className="xsmall-img" style={{marginTop: 0, filter: 'drop-shadow(2px 2px 1px rgba(0,0,0,.3))'}}/>
                          &nbsp;
                          <img src={impact1} className="xsmall-img rotated" style={{marginTop: -17}}/>
                          &nbsp;
                          <img className="marged-right marged-top insert-coin" src={insertCoin} style={{width: '3vw', height: 'auto', objectFit: 'contain'}} onClick={handleInsertCoin} />
                        </div>
                      </div>
                      
                    </div>
                    
                </div>
                
                <div className="bottom-rect"><div className="flex allspace bottom-rect-inner">
                  {
                    !isMobile
                    && <>
                    <Bulb
                      row
                      onOffMode
                      containerClass='allwidth spacearound'
                      inline={
                        Array.from(new Array(15)).map((e, i) => ({ color: i%2 ? 'red' : 'yellow', starton: i%2 > 0 }))  
                      }
                      size={vmax(1.5)} 
                      
                    />
                    <div className="flex flexrow allwidth">
                      <Bulb
                        onOffMode
                        containerClass='allheight spacearound'
                        inline={
                          Array.from(new Array(4)).map((e, i) => ({ color: i%2 ? 'red' : 'yellow', starton: i%2 > 0 }))  
                        }
                        size={vmax(1.5)} 
                        //size={20} 
                      />
                      <div className="bold xxlarge flex allspace centered"></div>
                      <Bulb
                        onOffMode
                        containerClass='allheight spacearound'
                        inline={
                          Array.from(new Array(4)).map((e, i) => ({ color: i%2 ? 'red' : 'yellow', starton: i%2 > 0 }))  
                        }
                        size={vmax(1.5)} 
                        //size={20} 
                      />
                    </div>
                    <Bulb
                      row
                      onOffMode
                      containerClass='allwidth spacearound'
                      inline={
                        Array.from(new Array(20)).map((e, i) => ({ color: i%2 ? 'red' : 'yellow', starton: i%2 > 0 }))  
                      }
                      size={vmax(1.5)} 
                        //size={20} 
                    /> 
                  </> 
                  }
                </div>
              </div>
            </div>

            <div className="flex alignstart clickable" id="machine-lever" onClick={handleLeverBallClick} style={{position: 'relative', left: `-1vmin`, top: '-20vh'}} >
                <div className="lever-base">
                    <div className="lever-base">
                    <div id="lever-bar" ref={leverBarRef}></div>
                    <div id="lever-ball" ref={leverBallRef} ></div>
                    </div>
                </div>
               
            </div>  
        </div>
        {/* <button onClick={() => setPlaying(true)}>Play</button> */}
        <div className="" style={{ position: 'fixed', bottom: 20, left: 20 }}>
          {
            rulesVisible
            ? <div className="flexy flexrow clickable drop-shadowed hoverscale" onClick={hideRules}>
              <BiArrowToLeft size={60} color='whitesmoke' />
            </div>
            : <div className="flexy flexrow clickable drop-shadowed hoverscale" onClick={showRules} >
              <BiArrowFromLeft size={60} color='whitesmoke' />

            </div>
          }
        </div>
        <div className="volume-option" style={{ position: 'fixed', bottom: 20, right: 20 }}>
          {
            ambiancePlaying
            ? <ImVolumeHigh size={50} color={'whitesmoke'} className='clickable drop-shadowed hoverscale' onClick={toggleAmbiance} />
            : <ImVolumeMute2 size={50} color={'whitesmoke'} className='clickable drop-shadowed hoverscale' onClick={toggleAmbiance} />
          }
        </div>
    </div>
  )
}