export function generateRandomSlotResults() {

  /* JACKPOT CASE */
    //return [ [4, 5, 0, 1, 2, 3], [4, 5, 0, 1, 2, 3], [4, 5, 0, 1, 2, 3], [4, 5, 0, 1, 2, 3] ];
    const arrays = [];
  
    for (let i = 0; i < 4; i++) {
      const array = [];
      let previousNum = -1; // Numéro précédent initialisé à une valeur différente de 0, 1, 2 ou 3
  
      for (let j = 0; j < 6; j++) {
        let num;
        
        if (j === 0) {
          num = Math.floor(Math.random() * 6); // Premier numéro aléatoire
        } else {
          if (previousNum === 5) {
            num = 0;
          } else {
            num = (previousNum + 1) % 6;
          }
        }
  
        array.push(num);
        previousNum = num;
      }
  
      arrays.push(array);
    }
  
    return arrays;
}

export async function computeResult(numbers) {
	const strNums = numbers.join(',');

  const withouts = await Promise.resolve(
    ['0','1','2','3'].map(i => strNums.replaceAll(i, '').length - 3)
  );
  
  const _totals = await Promise.resolve([
    4 - withouts[0], 
    4 - withouts[1], 
    4 - withouts[2], 
    4 - withouts[3]
  ]);
  const totals = await Promise.resolve(
    _totals
    .map((e, i) => ({[i]: e}))
    .reduce((a, b) => ({...a,...b}))
  );
  
  const bIndex = await Promise.resolve(
    Object.keys(totals).reduce((a, b) => ((totals[a] > totals[b]) ? a : b))
  );
  
  const bestIndex = parseInt(bIndex);
  
  const totalInline = await Promise.resolve(
  	(
      numbers[0] === bestIndex
      && numbers[1] === bestIndex
      && numbers[2] === bestIndex
      && numbers[3] === bestIndex
    )
    ? 4 
    : (
        (
           numbers[0] === bestIndex
          && numbers[1] === bestIndex
          && numbers[2] === bestIndex
        )
        || (
          numbers[1] === bestIndex
          && numbers[2] === bestIndex
          && numbers[3] === bestIndex
        )
      )
    ? 3
    : null
  );

  const threeInline = totalInline === 3;
  const fourInline = totalInline === 4;
  const jackpot = await Promise.resolve(
    fourInline
    && (bestIndex === 4)  // 4 = joe
  );
  const aabbPattern = !totalInline && await Promise.resolve(
    (numbers[0] === numbers[1])
    && (numbers[2] === numbers[3])
  );
  const ababPattern = !totalInline && await Promise.resolve(
    (numbers[0] === numbers[2])
    && (numbers[1] === numbers[3])
  );
  // const minThreeJoes = !totalInline && await Promise.resolve(
  //   //                      4 = joe
  //   numbers.filter(i => i===4).length >= 3  
  // );

  const twoDynamites = await Promise.resolve(
    numbers.filter(i => i===1).length >= 2
  )
    
  return {
    jackpot,
    fourInline,
    threeInline,
    aabbPattern,
    ababPattern,
    //minThreeJoes
    twoDynamites
  }
}