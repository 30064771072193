import './digital-screen.css';

const DigitalScreen = ({value, length, title, blinking, className, green}) => {
    const emptyDigits = length-(value?.toString()?.length);
    const greenClass = green ? '-green' : '';
    return (
        <div className={`flexy ${className||''}`}>
            {title && <p className='white bold large eroded shadowed'>{title}</p>}
            <div className={`digital-screen flexy flexrow xxlarge ${green?'digital-screen-green':''}`}>
                {
                    emptyDigits > 0 
                    && <span className={`digit-off${greenClass}`}>
                        { Array.from(new Array(emptyDigits)).map(() => 0) }
                    </span>
                }
                <span className={`digit-on${greenClass} ${blinking?`digital-screen-blinking${greenClass}`:''}`}>
                    {
                        value && value.toString().split('').map((d, di) => (
                            <span key={`digit-on_${di}`}>
                                <span className={`digit-off-noanim${greenClass}`}>0</span>
                                <span style={
                                    offset[(d==='1') ? '1' : 'default']
                                }>
                                    { d }
                                </span>
                            </span>
                        ))
                    }
                </span>
            </div>
        </div>
    )
}

export default DigitalScreen;

const offset = {
    '1': {marginLeft: -8, marginRight: -1},
    'default': {marginLeft: -16, marginRight: 0}
}